<script lang="ts" setup>
defineProps({
  top: Boolean
})
</script>
<script lang="ts">
export default {
  inheritAttrs: false
}
</script>

<template>
  <div class="absolute left-0 top-0 h-full w-full">
    <div
      class="flex h-full w-full flex-col items-center"
      :class="[top ? 'justify-start pt-112' : 'justify-center']"
    >
      <AppLogoSpinner v-bind="$attrs" />
    </div>
  </div>
</template>
